<template>
  <section id="member_signup">
    <div class="main">
      <div class="signup_box">
        <DecotTitle :title="$t('signup')" engTitle="CREATE ACCOUNT" />

        <re-easy-form v-model="easyForm" :rules="rules" :labelConfig="labelConfig" ref="reEasyForm" />

        <div class="other-options">
          <re-checkbox v-model="agreePrivacy" :label="this.$t('agree')" :render="SignupAgreement" />
        </div>

        <div class="btn-wrap">
          <re-button round="round" type="border" size="large" @click="submitEasyForm">{{
            $t("signup")
          }}</re-button>
        </div>
      </div>
    </div>

    <div class="sub" :style="{ backgroundImage: `url(${require('@/assets/img/member/bg_2.jpg')})` }">
      <div class="sub_box">
        <div class="main_title txt-center txt-white">
          <h3 class="eng">WELCOME NUWA</h3>
          <h5>
            {{ $t("areadyHasAccout") }}
            <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="" />
          </h5>
        </div>
        <router-link :to="{ name: 'Login' }" class="btn btn-white">
          {{ $t("login") }}
        </router-link>
      </div>
    </div>

    <re-dialog :visible.sync="visible" footerPosition="center" :template="SendSignupValidationEmail" />
  </section>
</template>

<script>
import '@/assets/scss/login.scss';
// import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { mapMutations, mapState } from 'vuex';

import DecotTitle from '@/components/global/DecoTitle.vue';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
// import ReButton from '@/components/form/ReButton.vue';
import { nationalityOptions, domainList } from '@/config/selectOptions';
import ReButton from '@/components/form/ReButton.vue';
import ReCheckbox from '@/components/form/ReCheckbox.vue';
import SignupAgreement from '@/components/other/SignupAgreement.vue';
import { signUpRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import SendSignupValidationEmail from '@/components/dialog/content/SendSignupValidationEmail.vue';
import ReDialog from '@/components/dialog/ReDialog.vue';
import { getApiLangCode } from '@/utils/getLangCode';

import { getCountryCode, nationalityList, timeZoneList, newSignUp } from '@/lib/cakeHttp';

// console.log('env...', process.env.NODE_ENV);

export default {
  name: 'Signup',
  components: { DecotTitle, ReEasyForm, ReCheckbox, ReButton, ReDialog },
  data() {
    return {
      visible: false,
      SendSignupValidationEmail,
      SignupAgreement: () => SignupAgreement,
      privacyPopup: false,
      ifPasswordAbleToSee: false,
      countryCode: [],
      password: '',
      checkPassword: '',
      agreePrivacy: false,
      // formData: {
      //   account: '',
      //   name: '',
      //   birthday: '',
      //   sex: '',
      //   country_code: '',
      //   phone: '',
      //   email: '',
      //   verify_code: '',
      //   agree_privicy: 0,
      //   send_way: '1',
      //   emergencyName: '',
      //   emergencyRelative: '',
      //   emergencyTel: '',
      // },
      defaultCountDown: 60,
      countDown: 0,
      countDownInterval: null,
      easyForm: [
        {
          label: this.$t('name'),
          prop: 'name',
          value: '',
          comp: 'input',
          placeholder: this.$t('namePlaceHolder'),
          layoutWidth: '50',
          required: true,
        },
        {
          label: this.$t('register.nation'),
          prop: 'country',
          value: '',
          comp: 'selectWithInputFilter',
          placeholder: this.$t('register.nation'),
          layoutWidth: '50',
          options: nationalityOptions,
          required: true,
        },
        {
          label: this.$t('uidOrPassportNum'),
          prop: 'account',
          value: '',
          comp: 'input',
          placeholder: this.$t('pidOrPassportNum'),
          layoutWidth: '50',
          required: true,
          notion1: this.$t('register.idNote1'),
          notion2: this.$t('register.idNote2'),
        },
        {
          label: this.$t('register.timeZone'),
          prop: 'timezone',
          value: '-',
          comp: 'select',
          placeholder: this.$t('loginPasswordPlaceholder'),
          layoutWidth: '50',
          options: nationalityOptions,
          required: true,
          mtauto: true,
        },
        {
          label: this.$t('phoneNumber'),
          prop: 'countryCode',
          value: '-',
          comp: 'select',
          placeholder: this.$t('loginPasswordPlaceholder'),
          layoutWidth: '20',
          options: [],
          required: true,
        },
        {
          label: '',
          prop: 'phone',
          value: '',
          comp: 'input',
          placeholder: this.$t('contactPlaceholder'),
          layoutWidth: '30',
        },
        {
          label: this.$t('gender'),
          prop: 'sex',
          value: '-',
          comp: 'select',
          placeholder: this.$t('gender'),
          layoutWidth: '50',
          options: [
            {
              label: this.$t('male'),
              value: 'm'
            },
            {
              label: this.$t('female'),
              value: 'f'
            }
          ],
          required: true,
        },
        {
          label: this.$t('address'),
          prop: 'addr',
          value: '',
          comp: 'input',
          placeholder: this.$t('address'),
          layoutWidth: '100',
          required: true,
        },
        {
          label: this.$t('date'),
          prop: 'birthday',
          value: '',
          comp: 'date-picker',
          placeholder: this.$t('pleaseInputBirthDay'),
          layoutWidth: '50',
          required: true,
        },
        {
          label: this.$t('form.email'),
          prop: 'email',
          value: '',
          comp: 'email-auto-complete',
          placeholder: this.$t('customSWALMailError'),
          layoutWidth: '50',
          options: domainList,
          required: true,
        },
        {
          label: this.$t('emergencyName'),
          prop: 'emergencyName',
          value: '',
          comp: 'input',
          placeholder: `${this.$t('pleaseEntry')}${this.$t('emergencyName')}`,
          layoutWidth: '30',
          required: true,
        },
        {
          label: this.$t('relative'),
          prop: 'emergencyRelative',
          value: '',
          comp: 'input',
          placeholder: `${this.$t('pleaseEntry')}${this.$t('relative')}`,
          layoutWidth: '20',
          required: true,
        },
        {
          label: this.$t('emergencyTel'),
          prop: 'emergencyTel',
          value: '',
          comp: 'input',
          placeholder: `${this.$t('pleaseEntry')}${this.$t('emergencyTel')}`,
          layoutWidth: '50',
          required: true,
        },
        {
          label: this.$t('password'),
          prop: 'password',
          value: '',
          comp: 'input',
          type: 'password',
          placeholder: this.$t('passwordPlaceholder'),
          layoutWidth: '50',
          required: true,
        },
        {
          label: this.$t('confirmPassword'),
          prop: 'confirmPassword',
          value: '',
          comp: 'input',
          type: 'password',
          placeholder: this.$t('refillPasswordPlaceholder'),
          layoutWidth: '50',
          required: true,
        }
      ],

      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    disableGetVerifyCode() {
      return this.countDownInterval && this.countDown > 0;
    },
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    },
    rules() {
      return signUpRule(this.filterData);
    }
  },
  watch: {
    'member.id': {
      handler(val) {
        if (val) {
          this.$router.push({ name: 'Home' });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['logIn', 'toggleShowMsg', 'setMsgInfo']),
    ...mapState(['member']),
    openSendEmailConfirm() {
      this.visible = true;
    },
    resetFields() {
      this.easyForm = this.easyForm.map((item) => {
        item.value = '';

        return item;
      });

      const refs = ['birthday', 'email'];
      for (let i = 0; i < refs.length; i += 1) {
        const item = this.$refs.reEasyForm.$refs[refs[i]][0];
        if (item && item.initValue) {
          item.initValue();
        }
      }
      this.$nextTick(() => {
        this.$refs.reEasyForm.removeErrorText();
      });
    },
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();

      console.log('checkForm.status', checkForm.status);
      if (!checkForm.status) {
        if (document.body.clientWidth <= 768) {
          const getErrorId = checkForm.validateResult.find((item) => !item.status).key;

          const scrollEle = document.querySelector(`#${getErrorId}`);

          const heightToTop = (ele) => {
            const root = document.body;
            let height = 0;
            do {
              height += ele.offsetTop;
              ele = ele.offsetParent;
            } while (ele !== root);
            return height;
          };

          window.scrollTo({
            top: heightToTop(scrollEle) - 30,
            behavior: 'smooth'
          });
        }

        return;
      }

      if (!this.agreePrivacy) {
        this.toggleShowMsg(true);
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('pleaseAgreePrivacy')
        });

        return;
      }

      // console.log('checkForm...', checkForm);
      if (checkForm && this.agreePrivacy) {
        const {
          account,
          name,
          birthday,
          countryCode,
          addr,
          phone,
          email,
          password,
          sex,
          country,
          timezone,
          emergencyName,
          emergencyRelative,
          emergencyTel,
        } = this.filterData;

        const payload = {
          account,
          name,
          birthday,
          country_code: countryCode.replace('+', ''),
          addr,
          phone,
          email,
          password,
          sex,
          country,
          timezone,
          agree_privicy: this.agreePrivacy ? 1 : 0, // agree_privicy 就是錯字不要懷疑
          reg_type: 'web',
          lang_id: getApiLangCode(),
          emergency_name: emergencyName,
          emergency_relative: emergencyRelative,
          emergency_tel: emergencyTel,
        };

        if (process.env.NODE_ENV === 'development') {
          payload.testing = 1;
        }

        // console.log('payload', payload);

        newSignUp(
          payload,
          () => {
            this.resetFields();
            this.toggleShowMsg(true);
            this.setMsgInfo({
              succeed: true,
              desc: this.$t('successfulRegistration'),
              callback: () => {
                this.toLogin();
              },
              triggerCallbackOnConfirmBtn: true
            });
            // this.openSendEmailConfirm();
          },
          (err) => {
            this.toggleShowMsg(true);
            this.setMsgInfo({
              succeed: false,
              desc: err
            });
          }
        );
      }
    },
    toLogin() {
      this.$router.push({
        name: 'Login'
      });
    },
    handlePrivacyPopUp(status) {
      this.privacyPopup = status;
      const el = document.getElementsByTagName('body')[0];

      if (status) {
        el.classList.add('lock');
      } else {
        el.classList.remove('lock');
      }
    },
    updateOptions(propKey, options) {
      this.easyForm = this.easyForm.map((item) => {
        if (item.prop === propKey) {
          item.options = options;
        }

        return item;
      });
    },
    async fetchNationality() {
      nationalityList({}, ({ data }) => {
        const lang = getApiLangCode();

        const options = data.map((item) => ({
          label: item[lang],
          value: item.id
        }));

        // console.log('options3', options);

        this.updateOptions('country', options);
      });
    },
    async fetchTimeZoneList() {
      timeZoneList({}, ({ data }) => {
        const lang = getApiLangCode();

        const options = data.map((item) => ({
          label: item[lang],
          value: item.id
        }));

        // console.log('options1', options);

        this.updateOptions('timezone', options);
      });
    },
    async fetchCountryCode() {
      getCountryCode().then((data) => {
        const options = data
          .filter((item, idx, arr) => arr.indexOf(item) === idx)
          .map((item) => ({
            label: `+${item.code2}(${item.country_code})`,
            value: `+${item.code2}`
          }));

        // console.log('options2', options);

        this.updateOptions('countryCode', options);
      });
    }
  },

  created() {
    this.fetchCountryCode();
    this.fetchNationality();
    this.fetchTimeZoneList();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

// 一段很醜的 code，為了對齊 ..
.remember {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
  }

  .label-privacy {
    line-height: 28.3px;
    cursor: pointer;
  }
}

button.disable {
  cursor: not-allowed;
}

.other-options {
  width: 300px;
  margin: 0 auto;
  // text-align: center;
}

.btn-wrap {
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
}
</style>
