<template>
  <div class="c-signup-agreement">
    <p class="signup-agreement">
      <span class="signup-agreement__text">{{ $t("agree") }}</span>
      <span class="signup-agreement__pop" @click.stop="handlePrivacyPopUp(true)">{{
        $t("privacyPolicy.title")
      }}</span>
    </p>

    <PrivacyPopup v-show="privacyPopup" @close="handlePrivacyPopUp(false)" />
  </div>
</template>
<script>
import PrivacyPopup from '@/components/global/PrivacyPopup.vue';

export default {
  name: 'SignupAgreement',
  components: {
    PrivacyPopup
  },
  data() {
    return {
      privacyPopup: false
    };
  },
  methods: {
    handlePrivacyPopUp(status) {
      this.privacyPopup = status;
      const el = document.getElementsByTagName('body')[0];

      if (status) {
        el.classList.add('lock');
      } else {
        el.classList.remove('lock');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.signup-agreement {
  &__text {
    @include font-style($c-grey1, 14);
  }

  &__pop {
    @include font-style($c-link, 14);
  }
}
</style>
