export const genderOptions = [
  {
    label: '男生',
    value: 'm'
  },
  {
    label: '女生',
    value: 'f'
  }
];

export const nationalityOptions = [
  { label: '臺灣', value: 'taiwan' },
  { label: '香港', value: 'hongkong' },
  { label: '中國', value: 'china' },
  { label: '新加坡', value: 'singapore' },
  { label: '日本', value: 'japanese' },
  { label: '韓國', value: 'korea' },
  { label: '美國', value: 'america' },
  { label: '加拿大', value: 'canada' },
  { label: '澳大利亞', value: 'australia' },
  { label: '澳門', value: 'macao' },
  { label: '紐西蘭', value: 'newzealand' },
  { label: '德國', value: 'germany' },
  { label: '義大利', value: 'italy' },
  { label: '法國', value: 'france' },
  { label: '荷蘭', value: 'netherlands' },
  { label: '馬來西亞', value: 'malaysia' },
  { label: '越南', value: 'vietnam' },
  { label: '菲律賓', value: 'philippines' },
  { label: '泰國', value: 'tailand' },
  { label: '印尼', value: 'indonesia' },
  { label: '英國', value: 'england' },
  { label: '瑞典', value: 'sweden' }
];

export const domainList = [
  {
    value: 'gmail.com',
    label: 'gmail.com',
  },
  {
    value: 'yahoo.com.tw',
    label: 'yahoo.com.tw',
  },
  {
    value: 'hotmail.com',
    label: 'hotmail.com',
  },
];
